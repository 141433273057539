<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 65%;display: flex;align-items: center;">
          <el-input
            class="hunt"
            v-model="input1"
            placeholder="请输入会员姓名"
            size="large"
            @input="orangutan"
          />
          <el-input
            class="hunt"
            v-model="topic"
            placeholder="请输入会员编码"
            size="large"
            @input="orangutan"
          />
          
            <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                size="large"
                @change="immediUpdate"
              />
          
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
      </div>
      <div class="summation">
        <div class="hint">统计 : {{zong}}条</div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :total="100"
        :empty-text="texts"
        :header-cell-style="{ background: '#F5F7FA' }"
      >
      <!-- 会员姓名 -->
        <el-table-column
          label="会员姓名"
          prop="name"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 会员编码 -->
        <el-table-column
          label="会员编码"
          prop="username"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 时间 -->
        <el-table-column
          label="操作时间"
          prop="createTime"
          align="center"
          :formatter="tiems"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 操作人 -->
        <el-table-column
          label="操作人"
          prop="adminName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>

        <el-table-column
          label="备注"
          prop="reason"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[6, 12, 18, 24]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="zong"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>
<script>
import { Search } from "@element-plus/icons-vue";
// import { useRouter  } from "vue-router";
import { ref,onMounted } from 'vue';
import { HYdefeated } from '../../utils/api'
import qs from 'qs'
import moments from 'moment'
// import { ElMessage } from "element-plus";
export default {
  name:"HYunsuccessful",
  setup() {
    // 总条数
    const totality=ref(0)
    const zong =ref(null)
    // 时间选择
    let value1=ref('')
    let a =ref('')
    let b =ref('')
    let c=ref('')
    let out=ref('')

    // 时间清空监听
    const immediUpdate=(e)=>{
      if(e!==null){
        a.value=new Date(e[0]).getTime()/1000
        b.value=new Date(e[1]).getTime()/1000
        c.value=moments(b.value*1000).format().substring(0,10)
        out.value= new Date( c.value+' '+ '23:59:59').getTime()/1000
      }else if(e==null){
        a.value=''
        b.value=''
        c.value=''
        out.value=''      
      }
      console.log(a.value)
      console.log(out.value)
    }





    // 列表内容显示
    let texts= ref('加载中')
    // 搜索框值变化清空
    let orangutan=()=>{
      // if(topic.value==''&&values.value==''){
      //   list()
      // }
    }
    // 会员姓名
    const input1=ref('')
    // 搜索加载
    const formative =ref(false)
    const search1=()=>{
      formative.value=true
      search()
    }
    // 搜索
    let topic=ref('')
    let search=()=>{
      console.log('会员姓名',input1.value)
      console.log('会员编码',topic.value)
      console.log('开始时间',a.value)
      console.log('结束时间',out.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        name:input1.value,
        username:topic.value,
        startTime:a.value,
        endTime:out.value
      }
      // console.log(data)
      HYdefeated(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          formative.value=false
          filterTableData.value=res.data.data.page.list
          zong.value=res.data.data.page.totalCount
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
          
        }else if(res.data.code!==0){
          formative.value=false
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
    }
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(input1.value!==''||topic.value!==''||a.value!==''||b.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(input1.value!==''||topic.value!==''||a.value!==''||b.value!==''){
        search()
      }else{
        list()
      }
    }
    // 时间转换
    let tiems=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
      }
    }
    // 工会动态列表
    let branches=ref(6)
    const list =()=>{
      let data ={
        limit:branches.value,
        page:currentPage.value,
        startTime:'',
        endTime:'',
        username:''
      }
      HYdefeated(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          filterTableData.value=res.data.data.page.list
          zong.value=res.data.data.page.totalCount
          // console.log(zong)
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    onMounted(()=>{
      list()
    })
    // 头部搜索
    let values=ref('')
    // 路由跳转
    // const router = useRouter()
    // 列表数据循环
    const filterTableData = ref([])
    return {
      formative,
      search1,
      totality,
      zong,
      immediUpdate,
      value1,
      texts,
      orangutan,
      // 会员姓名
      input1,
      // 搜索
      search,
      topic,
      // 分页
      handleSizeChange,
      handleCurrentChange,
      pagesize,
      currentPage,
      tiems,
      values,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Search
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
.el-select--large{
  width: 20%;
  // margin-left: 10px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 20%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
/* ::-webkit-scrollbar{display:none} */
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}

.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
